import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Splide, SplideSlide } from "@splidejs/react-splide";

function Gallery(props) {
  return (
    <section className="layout layout__color_primary">
      <Container fluid>
        <Row className="position-relative">
          <Col lg={3} xl={4} className="layout_mb__30">
            <h2 data-aos="fade-up">{props.items.title}</h2>
          </Col>
          <Col>
            <Splide
              className="splide_custom"
              aria-label="My Favorite Images"
              options={{
                gap: "40px",
                arrows: false,
                pagination: true,
                type: "fade",
                rewind: true,
                paginationDirection: "ltr",
                breakpoints: {
                  992: {
                    arrows: true,
                  },
                },
              }}
            >
              {props.items.slide.map((item, index) => {
                return (
                  <SplideSlide
                    data-aos="fade-up"
                    className="splide__slide__full"
                    key={`photo_${index}`}
                  >
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        item.thumb.data.attributes.url
                      }
                      alt={`photo_${index}`}
                      className="autoImg layout_image layout_image__round mb-4 mb-lg-0"
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Gallery;
