import Container from "react-bootstrap/Container";
import Query from "../../components/Query";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

import REVIEW_QUERY from "../../queries/review";

function Review() {
  return (
    <Query query={REVIEW_QUERY}>
      {({ data: { review } }) => {
        const element = review.data.attributes;
        return (
          <section className="layout layout__color_primary pb-0">
            <Container fluid>
              <h2 data-aos="fade-up">{element.title}</h2>
              <Splide
                hasTrack={false}
                options={{
                  rewind: true,
                  autoWidth: true,
                  gap: "1rem",
                  pagination: false,
                  arrows: true,
                }}
                aria-label="My Favorite Images"
                className="d-flex flex-column"
              >
                <SplideTrack className="w-100 layout_mt">
                  {element.review.map((item, index) => {
                    return (
                      <SplideSlide data-aos="fade-up" key={`review_${index}`}>
                        <h3 className="layout_mb__30 tt-normal">{item.name}</h3>
                        <p
                          className="layout_mb__30 layout_text"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <span
                          className={`layout_badge ${
                            item.type === "google"
                              ? "layout_badge__light"
                              : "layout_badge__primary"
                          }`}
                        >
                          <span
                            className={`layout_badge_icon layout_badge_${
                              item.type === "google"
                                ? "google"
                                : "clickclickdrive"
                            }`}
                          ></span>{" "}
                          {item.scores}
                        </span>
                      </SplideSlide>
                    );
                  })}
                </SplideTrack>

                <div className="d-flex gap-6 items-center ms-auto ms-md-0 justify-between newSplide">
                  <div className="splide__arrows d-flex gap-4">
                    <button
                      data-aos="fade-up"
                      className="splide__arrow splide__arrow--prev"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="22"
                        viewBox="0 0 11 22"
                        fill="none"
                      >
                        <path
                          d="M2 2L9.5 11L2 20"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </button>
                    <button
                      data-aos="fade-up"
                      className="splide__arrow splide__arrow--next"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="22"
                        viewBox="0 0 11 22"
                        fill="none"
                      >
                        <path
                          d="M2 2L9.5 11L2 20"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </Splide>
              <h4 data-aos="fade-up" className="h3 layout_mt layout_mb">
                Mehr Bewertungen hier:
              </h4>
              <div className="fix_left">
                <a
                  data-aos="fade-up"
                  href={element.glink}
                  className="btn btn__fill btn__fill_solid me-2 me-sm-4 me-md-5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GOOGLE
                </a>
                <a
                  data-aos="fade-up"
                  href={element.clickclicklink}
                  className="btn btn__fill btn__fill_solid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ClickClickDrive
                </a>
              </div>
            </Container>
          </section>
        );
      }}
    </Query>
  );
}

export default Review;
